<template>
  <div class="row justify-content-md-center mt2000">
    <div class="col-lg-10 col-md-10 col-12">
      <div class="card">
        <div class="card-header">
          <h4>AWS S3 Bucket fix issue</h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-3">
              <button
                class="btn btn-primary"
                @click="fnGetFilesInPoliceCheckFiles()"
              >
                Get files paths
              </button>
            </div>
            <div class="col-3">
              <button
                :disabled="result.length == 0"
                class="btn btn-primary"
                @click="fnAdd()"
              >
                Insert files paths
              </button>
            </div>
            <div class="col-3">
              <button class="btn btn-primary" @click="fnFileDownload()">
                <b-spinner small v-if="loading" class="mr-2"></b-spinner>
                Download Images paths
              </button>
            </div>
            <div class="col-3">
              <button class="btn btn-danger" disabled @click="fnEmptyBucket()">
                <b-spinner
                  small
                  v-if="loadingEmptyBucket"
                  class="mr-2"
                ></b-spinner>
                Empty Bucket
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as messageService from "../../services/message.service";
import * as tempAWSS3BucketFixService from "../../services/tempAWSS3BucketFix.service";
export default {
  name: "tempAWSS3BucketFixService",
  components: {},
  data() {
    return {
      allData: [],
      result: [],
      loading: false,
      loadingEmptyBucket: false,
    };
  },
  computed: {},
  methods: {
    fnGetFilesInPoliceCheckFiles: async function () {
      try {
        const response =
          await tempAWSS3BucketFixService.getFilesInPoliceCheckFiles();

        this.allData = response.data;

        this.result = this.fnGetUrls(this.allData);

        console.log("this.result", this.result);

        messageService.fnSweetAlertSuccessToast(
          "AWS got files",
          "Updated successfully"
        );
      } catch (error) {
        console.log("tempAWSS3BucketFixService", error);
      }
    },

    fnGetUrls(allData) {
      let array = [];
      for (let i = 0; i < allData.length; i++) {
        /**
         * Photo
         */
        if (allData[i].photo) {
          let str = allData[i].photo;
          let obj = {
            pid: allData[i].id,
            source: "photo_url",
            full_url: str,
            path: str.replace(
              "https://cv-screen.s3.ap-southeast-2.amazonaws.com/",
              ""
            ),
            key: str.replace(
              "https://cv-screen.s3.ap-southeast-2.amazonaws.com/police_check/",
              ""
            ),
          };
          array.push(obj);
        }

        /**
         * commencement_doc_urls
         */

        if (
          allData[i].commencement_doc_urls != null &&
          allData[i].commencement_doc_urls.length > 0
        ) {
          for (let j = 0; j < allData[i].commencement_doc_urls.length; j++) {
            let str = allData[i].commencement_doc_urls[j];
            let obj = {
              pid: allData[i].id,
              source: "commencement_doc_urls",
              full_url: str,
              path: str.replace(
                "https://cv-screen.s3.ap-southeast-2.amazonaws.com/",
                ""
              ),
              key: str.replace(
                "https://cv-screen.s3.ap-southeast-2.amazonaws.com/police_check/",
                ""
              ),
            };
            array.push(obj);
          }
        }

        /**
         * primary_doc_urls
         */
        if (
          allData[i].primary_doc_urls != null &&
          allData[i].primary_doc_urls.length > 0
        ) {
          for (let k = 0; k < allData[i].primary_doc_urls.length; k++) {
            let str = allData[i].primary_doc_urls[k];
            let obj = {
              pid: allData[i].id,
              source: "primary_doc_urls",
              full_url: str,
              path: str.replace(
                "https://cv-screen.s3.ap-southeast-2.amazonaws.com/",
                ""
              ),
              key: str.replace(
                "https://cv-screen.s3.ap-southeast-2.amazonaws.com/police_check/",
                ""
              ),
            };
            array.push(obj);
          }
        }

        /**
         * secondary1_doc_urls
         */
        if (
          allData[i].secondary1_doc_urls != null &&
          allData[i].secondary1_doc_urls.length > 0
        ) {
          for (let l = 0; l < allData[i].secondary1_doc_urls.length; l++) {
            let str = allData[i].secondary1_doc_urls[l];
            let obj = {
              pid: allData[i].id,
              source: "secondary1_doc_urls",
              full_url: str,
              path: str.replace(
                "https://cv-screen.s3.ap-southeast-2.amazonaws.com/",
                ""
              ),
              key: str.replace(
                "https://cv-screen.s3.ap-southeast-2.amazonaws.com/police_check/",
                ""
              ),
            };
            array.push(obj);
          }
        }

        /**
         * secondary2_doc_urls
         */
        if (
          allData[i].secondary2_doc_urls != null &&
          allData[i].secondary2_doc_urls.length > 0
        ) {
          for (let l = 0; l < allData[i].secondary2_doc_urls.length; l++) {
            let str = allData[i].secondary2_doc_urls[l];
            let obj = {
              pid: allData[i].id,
              source: "secondary2_doc_urls",
              full_url: str,
              path: str.replace(
                "https://cv-screen.s3.ap-southeast-2.amazonaws.com/",
                ""
              ),
              key: str.replace(
                "https://cv-screen.s3.ap-southeast-2.amazonaws.com/police_check/",
                ""
              ),
            };
            array.push(obj);
          }
        }

        // /**
        //  * identity_documents_same_name_urls
        //  */
        if (
          allData[i].identity_documents_same_name_urls != null &&
          allData[i].identity_documents_same_name_urls.length > 0
        ) {
          for (
            let l = 0;
            l < allData[i].identity_documents_same_name_urls.length;
            l++
          ) {
            let str = allData[i].identity_documents_same_name_urls[l];
            let obj = {
              pid: allData[i].id,
              source: "identity_documents_same_name_urls",
              full_url: str,
              path: str.replace(
                "https://cv-screen.s3.ap-southeast-2.amazonaws.com/",
                ""
              ),
              key: str.replace(
                "https://cv-screen.s3.ap-southeast-2.amazonaws.com/police_check/",
                ""
              ),
            };
            array.push(obj);
          }
        }

        /**
         * signature
         */
        if (allData[i].signature && allData[i].signature != null) {
          let str = allData[i].signature;
          let obj = {
            pid: allData[i].id,
            source: "signature",
            full_url: str,
            path: str.replace(
              "https://cv-screen.s3.ap-southeast-2.amazonaws.com/",
              ""
            ),
            key: str.replace(
              "https://cv-screen.s3.ap-southeast-2.amazonaws.com/police_check/",
              ""
            ),
          };
          array.push(obj);
        }

        /**
         * guardianSignature
         */
        if (
          allData[i].guardianSignature &&
          allData[i].guardianSignature != null
        ) {
          let str = allData[i].guardianSignature;
          let obj = {
            pid: allData[i].id,
            source: "guardianSignature",
            full_url: str,
            path: str.replace(
              "https://cv-screen.s3.ap-southeast-2.amazonaws.com/",
              ""
            ),
            key: str.replace(
              "https://cv-screen.s3.ap-southeast-2.amazonaws.com/police_check/",
              ""
            ),
          };
          array.push(obj);
        }

        /**
         * police_check_certificate_from_nss
         */
        if (
          allData[i].police_check_certificate_from_nss &&
          allData[i].police_check_certificate_from_nss != null
        ) {
          let str = allData[i].police_check_certificate_from_nss;
          let obj = {
            pid: allData[i].id,
            source: "police_check_certificate_from_nss",
            full_url: str,
            path: str.replace(
              "https://cv-screen.s3.ap-southeast-2.amazonaws.com/",
              ""
            ),
            key: str.replace(
              "https://cv-screen.s3.ap-southeast-2.amazonaws.com/police_check/",
              ""
            ),
          };
          array.push(obj);
        }

        /**
         * consent_partner
         */
        if (allData[i].consent_partner && allData[i].consent_partner != null) {
          let str = allData[i].consent_partner;
          let obj = {
            pid: allData[i].id,
            source: "consent_partner",
            full_url: str,
            path: str.replace(
              "https://cv-screen.s3.ap-southeast-2.amazonaws.com/",
              ""
            ),
            key: str.replace(
              "https://cv-screen.s3.ap-southeast-2.amazonaws.com/police_check/",
              ""
            ),
          };
          array.push(obj);
        }
      }

      return array;
    },

    fnAdd: async function () {
      try {
        const response = await tempAWSS3BucketFixService.add({
          result: this.result,
        });
        messageService.fnSweetAlertSuccessToast(
          "Add files into AWS",
          "Add successfully"
        );
      } catch (error) {
        console.log("tempAWSS3BucketFixService", error);
      }
    },
    fnFileDownload: async function () {
      this.loading = true;
      try {
        const response = await tempAWSS3BucketFixService.fileDownload();

        messageService.fnSweetAlertSuccessToast(
          "Download AWS files",
          "Successfully"
        );
        this.loading = false;
      } catch (error) {
        console.log("fnFileDownload", error);
      }
    },
    fnEmptyBucket: async function () {
      this.loadingEmptyBucket = true;
      try {
        const response = await tempAWSS3BucketFixService.emptyBucket();

        messageService.fnSweetAlertSuccessToast("Empty Bucket", "Successfully");
        this.loadingEmptyBucket = false;
      } catch (error) {
        console.log("fnFileDownload", error);
      }
    },
  },
};
</script>
