import { http} from './http_service';


// Get list for table
export function getFilesInPoliceCheckFiles(){
    return http().get(`/aws/get-police-check-files`);
}

export function add(data){
    return http().post(`/aws/add`, data);
}

export function fileDownload(){
    return http().get(`/aws/file-download`);
}

export function emptyBucket(){
    return http().get(`/aws/empty-bucket`);
}


